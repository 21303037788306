import React from 'react'
import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Checked from '../../images/icons/green-tick.svg'
import Cross from '../../images/icons/red-cross.svg'
import MobileTable from './MobileTable'
import {formatMoney} from '../../utils'

export default function ComparisonTable({title}) {
  const data = useStaticQuery(graphql`
    {
      firmPrice: wcProducts(slug: {eq: "kooi-pure-firm"}) {
        name
        price
      }

      plushPrice: wcProducts(slug: {eq: "kooi-pure-plush"}) {
        name
        price
      }

      mediumPrice: wcProducts(slug: {eq: "kooi-pure-medium"}) {
        name
        price
      }

      comparisons: wordpressWpMattress(slug: {eq: "comparison"}) {
        slug
        title
        acf {
          features {
            feature
            firm
            medium
            plush
          }
          firm {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80, srcSetBreakpoints: [100]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          plush {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80, srcSetBreakpoints: [100]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          medium {
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80, srcSetBreakpoints: [100]) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  `)

  const {firm, plush, medium, features} = data.comparisons.acf

  const {plushPrice, firmPrice, mediumPrice} = data

  const firmImage =
    firm && firm.localFile && firm.localFile.childImageSharp.fluid
  const mediumImage =
    medium && medium.localFile && medium.localFile.childImageSharp.fluid
  const plushImage =
    plush && plush.localFile && plush.localFile.childImageSharp.fluid

  return (
    <Container>
      <VisibleMobile>
        <MobileTable
          title="Kooi Pure Firm"
          price={formatMoney(firmPrice.price)}
          rows={features}
          filter="firm"
          img={firmImage}
          to="/product/kooi-pure-firm/"
        />
        <MobileTable
          title="Kooi Pure Medium"
          price={formatMoney(mediumPrice.price)}
          rows={features}
          filter="medium"
          img={mediumImage}
          to="/product/kooi-pure-medium/"
        />
        <MobileTable
          title="Kooi Pure Plush"
          price={formatMoney(plushPrice.price)}
          rows={features}
          filter="plush"
          img={plushImage}
          to="/product/kooi-pure-plush/"
        />
      </VisibleMobile>
      <Table>
        <thead>
          <tr>
            <th />
            <th width="20%">
              {firmImage && <Img fluid={firmImage} />}
              <h3>Kooi Pure Firm</h3>
            </th>
            <th width="20%">
              {mediumImage && <Img fluid={mediumImage} />}
              <h3>Kooi Pure Medium</h3>
            </th>
            <th width="20%">
              {plushImage && <Img fluid={plushImage} />}
              <h3>Kooi Pure Plush</h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {features.map(feature => (
            <tr>
              <td>{feature.feature}</td>
              <td>
                <img src={feature.firm ? Checked : Cross} alt="checked" />
              </td>
              <td>
                <img src={feature.medium ? Checked : Cross} alt="checked" />
              </td>
              <td>
                <img src={feature.plush ? Checked : Cross} alt="checked" />
              </td>
            </tr>
          ))}
          <tr className="price-row">
            <td>Mattress Price</td>
            <td>{formatMoney(firmPrice.price)}</td>
            <td>{formatMoney(mediumPrice.price)}</td>
            <td>{formatMoney(plushPrice.price)}</td>
          </tr>
          <tr className="button-row">
            <td />
            <td>
              <WhiteButton to="/product/kooi-pure-firm/">View Firm</WhiteButton>
            </td>
            <td>
              <WhiteButton to="/product/kooi-pure-medium/">
                View Medium
              </WhiteButton>
            </td>
            <td>
              <WhiteButton to="/product/kooi-pure-plush/">
                View Plush
              </WhiteButton>
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

const Container = styled.div`
  padding: 80px 0;
  h2.red {
    margin-bottom: 110px !important;
  }
`

const VisibleMobile = styled.div`
  display: block;
  @media (min-width: 900px) {
    display: none;
  }
`
const WhiteButton = styled(Link)`
  border: 2px solid ${({theme}) => theme.grey2};
  border-radius: 200px;
  color: ${({theme}) => theme.grey2};
  background: #fff;
  padding: 5px 20px;
  margin: 5px 0;
  display: inline-block;
  transition: 0.2s all ease-in-out;
  &:hover {
    color: #fff;
    background: ${({theme}) => theme.red};
    border: 2px solid ${({theme}) => theme.red};
  }
`
const Table = styled.table`
  @media (max-width: 900px) {
    display: none;
  }
  th img {
    padding: 20px;
  }
  width: 100%;
  .price-row td {
    background: ${props => props.theme.dark} !important;
    color: ${props => props.theme.white};
    height: 80px;
    font-weight: 600;
    font-size: 23px;
  }
  .button-row td {
    background: #fff !important;
  }
  thead {
    th {
      padding-bottom: 60px;
      &:first-child {
        text-align: left;
        width: 30%;
        padding-right: 40px;
        h3 {
          font-size: 24px;
        }
        p {
          font-weight: 100;
        }
      }
    }
  }
  tbody {
    tr td:first-child {
      background: ${({theme}) => theme.grey1};
      color: white;
      text-align: left;
      font-size: 20px;
      padding-left: 20px;
    }
    tr:nth-child(even) {
      background: ${({theme}) => theme.grey3};

      td:first-child {
        background: ${({theme}) => theme.grey2};
      }
    }
    tr:nth-child(odd) {
      background: ${({theme}) => theme.grey4};
    }
    td {
      text-transform: capitalize;
      text-align: center;
      padding: 10px;
      line-height: 23px;
    }
    img {
      height: 30px;
    }
    tr:last-child td {
      font-size: 18px;
    }
  }
`
