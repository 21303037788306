import React from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Checked from '../../images/icons/green-tick.svg'

export default function MobileTable({title, img, rows, filter, price, to}) {
  return (
    <Container>
      {img && <Img fluid={img} />}
      <Title>{title}</Title>
      <ul>
        {rows.map(
          feature =>
            feature[filter] && (
              <li>
                <img src={Checked} alt="checked icon" />
                <p>{feature.feature}</p>
              </li>
            ),
        )}
      </ul>
      <Price>
        Mattress Price: <strong>{price}</strong>
        <WhiteButton to={to}>View {title}</WhiteButton>
      </Price>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 5px 15px 0 rgba(50, 50, 93, 0.12);
  margin-bottom: 80px;
  &:last-child {
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin-block-start: 20px;
    margin-block-end: 20px;
    padding-inline-start: 20px;
    li {
      display: flex;
      text-transform: capitalize;
      align-items: center;
      padding-bottom: 10px;
    }
    p {
      display: inline-block;
      padding-left: 20px;
    }
    img {
      height: 25px;
    }
  }
`

const Title = styled.div`
  background: ${({theme}) => theme.grey1};
  color: white;
  padding: 20px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
`

const Price = styled.div`
  background: ${({theme}) => theme.grey1};
  color: white;
  padding: 20px;
  font-size: 24px;
  text-align: center;
`

const WhiteButton = styled(Link)`
  border: 2px solid white;
  border-radius: 200px;
  color: white;
  background: ${({theme}) => theme.grey1};
  padding: 5px 20px;
  transition: 0.2s all ease-in-out;
  margin: 20px;
  display: block;
  @media (max-width: 550px) {
    display: block;
    padding: 15px;
    margin: 20px 10px 10px 10px;
  }
  &:hover {
    color: white;
    background: ${({theme}) => theme.red};
    border: 2px solid ${({theme}) => theme.red};
  }
`
